import { Link } from '@remix-run/react'
import { izzyPhoneNumber, izzyPhoneNumberLink } from '#app/utils/misc'
import { Button } from './ui/button'

export default function IzzySteps() {
	return (
		<section className="mx-auto max-w-7xl px-6 py-16">
			<div className="mb-14 text-center text-[40px] font-semibold leading-[60px] text-slate-700">
				Objednáno za 7 minut, uklizeno za pár hodin
			</div>
			<div className="grid gap-6 sm:grid-cols-2 lg:grid-flow-col lg:grid-cols-4">
				<div>
					<img
						className="w-full"
						src="/img/steps/1.webp"
						width={245}
						height={205}
						alt="step 1"
					/>
					<div className="pt-4 max-lg:px-10 max-lg:text-center">
						<span className="text-lg font-semibold leading-[27px] text-slate-700">
							Stáhněte si aplikaci
							<br />
						</span>
						<span className="font-normal leading-tight text-slate-700">
							Úklidy můžete řídit z webu nebo mobilní aplikace.
						</span>
					</div>
				</div>
				<div>
					<img
						className="w-full"
						src="/img/steps/2.webp"
						width={245}
						height={205}
						alt="step 2"
					/>
					<div className="pt-4 max-lg:px-10 max-lg:text-center">
						<span className="text-lg font-semibold leading-[27px] text-slate-700">
							Vyplňte objednávku
							<br />
						</span>
						<span className="font-normal leading-tight text-slate-700">
							Detaily si domluvte v chatu aplikace.
						</span>
					</div>
				</div>
				<div>
					<img
						className="w-full"
						src="/img/steps/3.webp"
						width={245}
						height={205}
						alt="step 3"
					/>
					<div className="pt-4 max-lg:px-10 max-lg:text-center">
						<span className="text-lg font-semibold leading-[27px] text-slate-700">
							Průběh úklidu
							<br />
						</span>
						<span className="font-normal leading-tight text-slate-700">
							Věnujte čas tomu, co vás baví a úklid nechte na nás.
						</span>
					</div>
				</div>
				<div>
					<img
						className="w-full"
						src="/img/steps/4.webp"
						width={245}
						height={205}
						alt="step 4"
					/>
					<div className="pt-4 max-lg:px-10 max-lg:text-center">
						<span className="text-lg font-semibold leading-[27px] text-slate-700">
							Uklizeno, Izzy
							<br />
						</span>
						<span className="font-normal leading-tight text-slate-700">
							Po dokončení úklidu ohodnoťte vaši spokojenost.
						</span>
					</div>
				</div>
			</div>
			<div className="pt-12 text-center">
				<span className="leading-tight text-slate-700">
					Potřebujete více informací, nebo si něčím nejste jisti?
					<br />
					Mrkněte do{' '}
					<Link
						prefetch="intent"
						to="/contact#faq-section"
						className="underline"
					>
						FAQ
					</Link>{' '}
					nebo se nám ozvěte na
					<a href={izzyPhoneNumberLink} className="underline">
						{' '}
						{izzyPhoneNumber}
					</a>{' '}
					nebo na emailu{' '}
					<Link to="mailto:info@izzy.cz" className="underline">
						info@izzy.cz
					</Link>
				</span>
			</div>
			<div className="mt-12">
				<div className="lg:text-center">
					<Button
						asChild
						variant="default"
						type="submit"
						className="h-[75px] w-[300px]"
					>
						<Link
							className="max-lg:w-full"
							to="/dashboard/order-1"
							prefetch="intent"
						>
							Objednat úklid
						</Link>
					</Button>
				</div>
			</div>
		</section>
	)
}
